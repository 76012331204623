<template>
  <div
    id="step5"
    class="section__common__container"
    style="word-break: keep-all;"
  >
    <div class="pc:flex pc:pt-18 tablet:pt-12 mobile:pt-8 pc:pb-24 tablet:pb-12 mobile:pb-12">
      <div
        class="pc:w-1/4"
      >
        <div class="pc:text-xl tablet:text-[18px] mobile:text-sm font-bold pb-1.5 text-orange-500">
          STEP 5
        </div>
        <div class="pc:text-5xl tablet:text-[36px] mobile:text-3xl font-bold text-gray-700">
          최종 오퍼
        </div>
      </div>
      <div
        class="pc:w-3/4 mw-845px pt-9"
      >
        <div
          class="text-gray-700 pc:text-2xl tablet:text-xl mobile:text-base"
          style="word-break: keep-all;"
        >
          경영진 면접까지 통과하시어 최종 합격하시게 되면 채용담당자가 유선과 이메일을 통해 연락을 드리고, 최종 처우를 제안 드립니다. 지원자님께서 오퍼 레터를 검토하신 후 최종 입사를 결정하시면, 최종 입사일과 입사 시 지참하셔야 할 서류 및 쿡앱스 생활 가이드 파일을 보내 드립니다.
        </div>
        <img
          src="@/assets/careers/process/offer_img.png"
          alt=""
          class="mt-10 mb-16"
          style="width: 100%;"
        >
        <div class="text-center">
          <div
            class="mt-10 mb-10 text-gray-600 pc:text-2xl tablet:text-xl mobile:text-base"
            style="word-break: keep-all;"
          >
            쿡앱스가 만들어가는 즐거운 세상, 지원자님과 함께 만들고 싶습니다.<br>
            <b class="text-orange-500 text-bold">지금 바로 쿡앱스에 합류하세요.</b>
          </div>
          <button
            class="py-3 bg-orange-500 px-9 pc:rounded-5xl tablet:rounded-5xl mobile:rounded-4xl"
            @click="$router.push('/careers/list')"
          >
            <span
              class="font-bold text-white pc:text-2xl tablet:text-2xl mobile:text-xl"
            >채용 중인 직무 바로가기!</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
blockquote{
  position: relative;
  padding-left: 32px;
  &::before{
    content: '';
    position: absolute;
    top: 0; left: 0;
    width: 2px; height: 100%;
    background-color: #bebebe;
  }
}
</style>