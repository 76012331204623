<template>
  <div
    ref="faq"
    style="background-color: rgba(245, 245, 245, 0.5);"
    name="faq"
    class="faq"
  >
    <div
      class="section__common__container"
      style="word-break: keep-all;"
    >
      <div class="pc:flex pc:pt-18 tablet:pt-12 mobile:pt-8 pc:pb-30 tablet:pb-26 mobile:pb-24">
        <div class="pc:w-1/4">
          <div class="font-bold text-gray-700 pc:text-5xl tablet:text-3xl mobile:text-3xl">
            자주 묻는 질문
          </div>
        </div>
        <div
          class="pc:w-3/4 mw-845px mt-m-38 tablet:pt-6 mobile:pt-4"
        >
          <div
            v-for="(item, idx) in questions"
            :key="idx"
            class="py-5 border-b"
          >
            <div
              class="flex question"
              :class="{ 'on' : item.isOpened }"
              @click="toggleQuestion(item.idx, item.isOpened)"
            >
              <div
                class="text-gray-700 pc:text-2xl tablet:text-xl mobile:text-base hover:text-orange-500"
                style="flex-grow: 1;"
              >
                {{ item.question }}
              </div>
              <i
                v-if="!item.isOpened"
                class="pt-2 pl-2 icon-arrow-down"
              />
              <i
                v-else
                class="pt-2 pl-2 icon-arrow-up"
              />
            </div>
            <div
              class="answer pc:text-2xl tablet:text-xl mobile:text-base pc:pt-4 tablet:pt-2 mobile:pt-2"
              :class="{ 'on' : item.isOpened }"
            >
              <span
                class="text-gray-700"
                v-html="item.answer"
              />
              <button
                v-if="item.button"
                class="text-orange-500 underline"
                @click="showInterview"
              >
                경영진 인터뷰
              </button>
            </div>
          </div>
          <div class="text-center pc:pt-12 tablet:pt-10 mobile:pt-8">
            <button
              class="inline-block bg-orange-500 px-10 tablet:rounded-5xl tablet:py-[17px] mobile:rounded-4xl mobile:py-[13px]"
              @click="$router.push('/faq')"
            >
              <span
                class="font-bold text-white tablet:text-[24px] tablet:leading-[1.25] tablet:tracking-[-0.2px] mobile:text-xl mobile:leading-[1.3]"
              >질문과 답변 더보기</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      questions: [
        {
          idx: 1,
          question: '전형에 대한 결과는 언제 알 수 있나요? ',
          answer: '서류,과제,면접 전형의 경우에는 영업일 기준 최대 일주일 이내로 최대한 빠르게 안내드리고 있습니다. 타사 채용 전형이 동시에 진행되고 있다면 미리 말씀해 주세요. 지원자님의 보다 나은 결정을 위하여, 쿡앱스 면접 전형의 신속한 일정 조율을 도와드리고 있습니다. 명시된 기간 이상으로 지연되는 경우에는 <b class="text-orange-500">recruit@cookapps.com</b>으로 문의하시면 빠르게 확인해 드리겠습니다.',
          isOpened: false
        },
        {
          idx: 2,
          question: '쿡앱스는 학력, 나이 얼마나 보나요?',
          answer: '학력과 나이를 기준으로 채용을 진행하는 직무는 없습니다. 다만 전문연구요원의 경우 이공계 석사 학위 소지를 하신 분이 신규 편입의 기본 조건이기 때문에 해당 자격 요건이 필요합니다. 이외의 포지션의 경우 학력, 나이로 채용에 있어 차등을 두고 있지 않습니다.',
          isOpened: false
        },
        {
          idx: 3,
          question: '자기소개서를 따로 받지 않는 이유가 있나요?',
          answer: '수려한 글쓰기 실력보다 지원해 주신 직무와 관련된 경력사항이나 프로젝트 수행 이력, 역할 등 경력과 관련된 이력을 더 중점적으로 보고 있습니다.<br><br>다만 신입, 인턴 지원자분들의 경우, 게임 제작 경험이 없다면 미리 작성하신 별도의 지원 동기, 게임 플레이 경험 등 참고할 수 있는 추가 자료를 첨부해 주세요.',
          isOpened: false
        },
        {
          idx: 4,
          question: '쿡앱스는 수시 채용을 진행하고 있나요? 전체 전형 진행 기간은 어떻게 되나요?',
          answer: '쿡앱스는 전체 직무에 대해 상시 채용을 진행하고 있습니다. 전형 기간의 경우 과제 유/무, 인턴 채용/정규직 채용에 따라 조금씩 다를 수 있으나 서류 접수부터 최종 합격까지 평균 3주~5주의 시간이 소요되고 있습니다. 좋은 경험과 역량을 갖추고 계신 분이라면 언제든지 쿡앱스 가족으로 맞이할 준비가 되어있으니 많은 관심과 지원 부탁드립니다.',
          isOpened: false
        },
        {
          idx: 5,
          question: '쿡앱스의 인재상은 무엇인가요?',
          answer: '쿡앱스의 게임 제작 환경에서는 호기심이 많으신 분, 문제 해결에 적극적이고 주도적인 분, 그리고 성장 욕구가 있으신 분을 선호하고 있습니다. 더 자세한 내용은 경영팀 인터뷰를 참고해주세요.<br><br>',
          button: true,
          isOpened: false
        },
        {
          idx: 6,
          question: '재지원이 가능한가요? 재지원자에게 가장 중요하게 보는 것은 무엇인가요?',
          answer: '현재 쿡앱스 내에는 4개의 게임 제작 스튜디오가 운영되고 있으며, 스튜디오 운영 상황과 프로젝트의 성격에 따라 채용하고자 하는 역량이 달라질 수 있기 때문에 재지원에는 제한이 없습니다.<br><br>다만 재지원 시에는 쿡앱스 채용공고, 채용 프로세스 페이지, 스토리와 같은 콘텐츠를 참고해 지원 직무에 맞춘 이력서와 포트폴리오로 추가 / 수정해 보내주신다면 검토에 많은 도움이 됩니다.',
          isOpened: false
        },
        {
          idx: 7,
          question: '피드백을 받을 수 있을까요?',
          answer: '과제 및 인터뷰 전형에서 탈락 안내 이후 본인 희망 시 심사 결과에 대한 간단한 피드백을 제공해 드리고 있습니다. 이메일 회신을 통해 문의하신다면 개별 답변을 드리도록 하겠습니다.',
          isOpened: false
        },
        {
          idx: 8,
          question: '게임 제작 경험이 없는 신입도 채용 가능성이 있나요?',
          answer: '인턴/신입 포지션의 경우 게임 제작 경험이 필수 자격요건은 아닙니다. 동아리, 아카데미, 게임잼 등을 통해 1인 혹은 소규모 팀으로 게임을 제작하신 경험이 있으시다면 우대를 해드리고 있지만 해당 경험이 없으신 경우에도 게임 개발에 대한 관심도 및 플레이 경험을 포트폴리오나 별도의 자기소개서에 잘 기술해 주신다면 지원자분의 역량을 판단하는 데에 많은 참고가 될 것 같습니다.',
          isOpened: false
        },
        {
          idx: 9,
          question: '인디 게임 개발 경험, 지원에 도움이 될까요? ',
          answer: '게임에 대한 애정을 바탕으로 개발 초기 단계부터 론칭까지 경험해 보신 분이라면 인디 게임 개발 경험은 긍정적인 요소로 판단하고 있습니다. 제작하셨던 게임의 핵심 재미, 타깃 시장과 유저 분석, 프로젝트 성공/실패에 대한 이유를 포트폴리오에서 설명해 주세요.',
          isOpened: false
        },
      ]
    };
  },
  methods: {
    toggleQuestion(index, opened) {
      this.questions.map(item => { return item.isOpened = false; });
      this.questions.filter(item => item.idx === index)[0].isOpened = !opened;
    },
    showInterview(){
      document.documentElement.style.overflowY = 'hidden';
      this.$store.dispatch('app/setShowYoutube', true);
      this.$store.dispatch('app/setCurrentVideo', {videoId: 'ees_Ik4UfLs'});
    }
  },
};
</script>
<style lang="scss">
.pc .mt-m-38{
  margin-top: -38px;
}
</style>

<style lang="scss" scoped>
.question{
  user-select: none;
  cursor: pointer;
  & > div{
    text-indent: -23px;
    padding-left: 24px;
    :is(.mobile) &{
      text-indent: -19px;
      padding-left: 21px;
    }
  }
  & > div::before{
    content: 'Q. ';
  }
  div:hover, &.on div{
    font-weight: bold;
  }
  &.on div{
    font-weight: bold;
    color: $orange-500
  }
}
.answer{
  max-height: 0;
  overflow: hidden;
  height: auto;
  text-indent: -23px;
  padding-left: 24px;
  :is(.mobile) &{
    text-indent: -19px;
    padding-left: 21px;
  }
  &::before{
    content: 'A. ';
  }
  &:not(.on){
    padding: 0 !important;
  }
  &.on{
    max-height: 500px;
  }
}
</style>