<template>
  <div
    id="step1"
    class="section__common__container"
    style="word-break: keep-all;"
  >
    <div class="pc:flex pc:pt-18 tablet:pt-12 mobile:pt-8">
      <div class="pc:w-1/4 tablet:w-full mobile:w-full">
        <div class="pc:text-xl tablet:text-[18px] mobile:text-sm font-bold pb-1.5 text-orange-500">
          STEP 1
        </div>
        <div
          v-if="$screen.breakpoint === 'pc'"
          class="font-bold text-gray-700 pc:text-5xl tablet:text-3xl mobile:text-3xl"
        >
          쿡앱스에 대해<br>
          알아보기
        </div>
        <div
          v-else
          class="font-bold text-gray-700 pc:text-5xl tablet:text-[36px] mobile:text-3xl"
        >
          쿡앱스에 대해 알아보기
        </div>
      </div>
      <div
        class="pc:w-3/4 tablet:w-full mobile:w-full mobile:pt-[10px] pc:flex tablet:flex relative pt-9"
      >
        <div
          class="pc:text-2xl tablet:text-xl mobile:text-base pc:pr-6 tablet:pr-6 pc:w-19/25 tablet:19/25 mobile:w-full"
        >
          쿡앱스의 게임을 만드는 방식은 조금 독특합니다. 시장성과 게임성 모두 만족하는 게임을 만들기 위해서 이미 검증된 게임을 따라 제작해보기도 하고, 기존에 출시한 게임의 단점을 보완해 쿡앱스만의 새로운 게임을 만들어 내기도 합니다. 시장의 흐름을 빠르게 파악하고 사용자가 원하는 게임을 적시에 제공하는 쿡앱스만의 특별한 게임 제작 방식 중 하나이죠. 쿡앱스의 모든 것이 낯설게 느껴질 여러분에게 우리가 생각하는 핵심 가치, 제작 방식, 인재상을 공유해 드립니다. 지원하시기 전 쿡앱스에 대해 더 자세히 알아보고 이해할 수 있도록 도와드리고 싶어요. 더 많은 이야기는 <a
            href="https://story.cookapps.com/"
            target="_blank"
            class="text-orange-500 underline"
          >쿡앱스 스토리</a>, <a
            href="https://www.youtube.com/channel/UCpOylBBGTmdTkPGJhkkl_RA"
            target="_blank"
            class="text-orange-500 underline"
          >쿡앱스 유튜브 채널</a>에서 확인하실 수 있습니다.
        </div>
        <div
          v-if="$screen.breakpoint !== 'mobile'"
          class="text-lg text-orange-500 pc:w-6/25 tablet:w-6/25"
        >
          아! 물론 게임도 플레이해
          보며 쿡앱스가 어떤 게임을 만들고 있는지 확인해
          보시는 것도 추천드립니다.
        </div>
      </div>
    </div>
    <div class="text-right">
      <div class="inline-block text-left pc:w-3/4">
        <div
          class="pb-12 pt-14 tablet:pt-12"
          style="display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 40px 4%;
                align-content: flex-start;"
        >
          <div
            class="cursor-pointer mw-411px hover:underline"
            style="width: 48%;"
            @click="showYoutube('ees_Ik4UfLs')"
          >
            <div class="more__thumbnail">
              <img
                class="mb-3"
                src="@/assets/careers/process/more_01.png"
                alt="Why를 찾는 사람들"
              >
            </div>
            <div class="mt-3 pc:text-xl tablet:text-sm mobile:text-sm">
              쿡앱스 CEO, COO가 말하는 쿡앱스 인재, Why를
              찾는 사람들
            </div>
          </div>
          <!-- <div
            class="cursor-pointer mw-411px hover:underline"
            style="width: 48%;"
            @click="$router.push('/cookapps-story/123')"
          >
            <div class="more__thumbnail">
              <img
                class="mb-3"
                src="@/assets/careers/process/more_02.png"
                alt="쿡앱스가 재밌는 게임을 만드는 5가지 이유"
              >
            </div>
            <div class="mt-3 pc:text-xl tablet:text-sm mobile:text-sm">
              쿡앱스가 재밌는 게임을 만드는 5가지 이유
            </div>
          </div> -->
          <div
            class="cursor-pointer mw-411px hover:underline"
            style="width: 48%;"
            @click="$router.push('/cookapps-story/186')"
          >
            <div class="more__thumbnail">
              <img
                class="mb-3"
                src="@/assets/careers/process/more_05.png"
                alt="쿡앱스의 게임 개발 방식, MVP!"
              >
            </div>
            <div class="mt-3 pc:text-xl tablet:text-sm mobile:text-sm">
              쿡앱스의 게임 개발 방식, MVP!
            </div>
          </div>
          <div
            class="cursor-pointer mw-411px hover:underline"
            style="width: 48%;"
            @click="$router.push('/cookapps-story/122')"
          >
            <div class="more__thumbnail">
              <img
                class="mb-3"
                src="@/assets/careers/process/more_03.png"
                alt="쿡앱스의 복리후생"
              >
            </div>
            <div class="mt-3 pc:text-xl tablet:text-sm mobile:text-sm">
              쿡앱스, 어디까지 찾아봤니? 판교 One Top! 쿡앱스 복리후생
            </div>
          </div>
          <!-- <div
            class="cursor-pointer mw-411px hover:underline"
            style="width: 48%;"
            @click="showYoutube('dIURhe60J8I')"
          >
            <div class="more__thumbnail">
              <img
                class="mb-3"
                src="@/assets/careers/process/more_04.png"
                alt="지금 여기, 쿡앱스 사람들"
              >
            </div>
            <div class="mt-3 pc:text-xl tablet:text-sm mobile:text-sm">
              Inside CookApps, 쿡앱스 사람들이 말하는 쿡앱스
            </div>
          </div> -->
          <div
            class="cursor-pointer mw-411px hover:underline"
            style="width: 48%;"
            @click="$router.push('/cookapps-story/175')"
          >
            <div class="more__thumbnail">
              <img
                class="mb-3"
                src="@/assets/careers/process/more_06.png"
                alt="숫자로 알아보는 쿡앱스"
              >
            </div>
            <div class="mt-3 pc:text-xl tablet:text-sm mobile:text-sm">
              숫자로 알아보는 쿡앱스
            </div>
          </div>
          <div
            class="w-full tablet:mt-10 tablet:mb-0"
            style="background-color: #e1e1e1; height: 1px;"
          />
          <div class="pt-10 pb-4">
            <div class="pb-3 text-3xl font-bold text-orange-500">
              나에게 어울리는 직무를 찾아보세요.
            </div>
            <div class="pb-10 text-gray-700 mw-845px pc:text-2xl tablet:text-xl mobile:text-base">
              게임 기획, 개발, 디자인, 시나리오, 데이터 분석, 마케팅, 로컬라이제이션, 인사 등 전 부문의 채용을 진행 중입니다. 쿡앱스 <a
                href="https://www.cookapps.com/careers"
                target="_blank"
                class="text-orange-500 underline"
              >채용 페이지</a>에서 자세한 내용이 확인 가능합니다. 혹시 지원하고 싶은 포지션이 열려있지 않다면 채용담당자에게 문의해주세요. 쿡앱스에 어울리는 포지션이 있을지 채용 담당자가 직접 찾아 드리겠습니다.
            </div>
            <blockquote class="text-orange-500 mw-845px pc:text-4xl tablet:text-2xl mobile:text-2xl">
              “지원하시기 전에 내가 가진 강점이 무엇인지 생각해보세요. 그리고 그 강점이 지원하실 직무에서 어떻게 활용될지, 쿡앱스의 게임 제작 방식과 잘 맞을지 생각해보는 것은 어떨까요? 쿡앱스에서의 나의 역할이 머리 속에서 구체적으로 그려진다면 지원 준비는 끝"
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      video: '',
    };
  },
  methods: {
    showYoutube(video) {
      document.documentElement.style.overflowY = 'hidden';
      this.$store.dispatch('app/setShowYoutube', true);
      this.$store.dispatch('app/setCurrentVideo', {videoId: video});
    }
  },
};
</script>

<style lang="scss" scoped>
.more__thumbnail{
  overflow: hidden;
  position: relative;
  max-width: 411px;
  width: 100%;
  height: 0;
  padding-bottom: 56.4%;
  border-radius: .5rem;
  img{
    width: 100% !important; 
    height: 100%;
    position: absolute;
    top: 0; left: 0;
    transition: .4s;
    overflow: hidden;
  }
  &:hover img{
    transform: scale(1.1);
  }
}
blockquote{
  position: relative;
  padding-left: 36px;
  &::before{
    content: '';
    position: absolute;
    top: 0; left: 0;
    width: 2px; height: 100%;
    background-color: $orange-500;
  }
}
</style>