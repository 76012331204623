<template>
  <div>
    <page-top-banner :data="bannerInfo" />
    <process />
    <div style="background-color: #e1e1e1; height: 1px;" />
    <step1 />
    <div style="background-color: #e1e1e1; height: 1px;" />
    <step2 />
    <div style="background-color: #e1e1e1; height: 1px;" />
    <step3 />
    <img
      src="@/assets/careers/process/interview_img.png"
      alt="인터뷰 중인 이미지"
      style="min-height: 90px; width: 100%; object-fit: cover;"
    >
    <step4 />
    <div style="background-color: #e1e1e1; height: 1px;" />
    <step5 />
    <div style="background-color: #e1e1e1; height: 1px;" />
    <ask />
    <VideoModal v-if="$store.state.app.showYoutube" />
  </div>
</template>

<script>
import PageTopBanner from '../../layout/components/PageTopBanner.vue';
import Process from './components/process.vue';
import step1 from './components/step1.vue';
import step2 from './components/step2.vue';
import step3 from './components/step3.vue';
import step4 from './components/step4.vue';
import step5 from './components/step5.vue';
import ask from './components/ask.vue';
import VideoModal from './components/VideoModal.vue';

export default {
  name: 'HowWeHire',
  components: { PageTopBanner, Process, step1, step2, step3, step4, step5, ask, VideoModal },
  data() {
    return {
      bannerInfo: {
        title: 'How we hire'
      },
    };
  },
};
</script>

<style lang="scss">
.pc .mw-845px{
  max-width: 845px;
}
.pc .mw-411px{
  max-width: 411px;
}
</style>