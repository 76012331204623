<template>
  <div
    id="step2"
    class="section__common__container"
    style="word-break: keep-all;"
  >
    <div class="pc:flex pc:pt-18 tablet:pt-12 mobile:pt-8 pc:pb-24 tablet:pb-12 mobile:pb-12">
      <div class="pc:w-1/4">
        <div class="pc:text-xl tablet:text-[18px] mobile:text-sm font-bold pb-1.5 text-orange-500">
          STEP 2
        </div>
        <div class="font-bold text-gray-700 pc:text-5xl tablet:text-[36px] mobile:text-3xl">
          지원서 작성하기
        </div>
      </div>
      <div
        class="pc:w-3/4 mw-845px pt-9"
      >
        <div
          class="text-gray-700 pc:text-2xl tablet:text-xl mobile:text-base"
        >
          새로운 회사에 지원하실 때 가장 어려운 단계 중 하나입니다. 특히 자기소개서는 짧은 글로 내가 어떤 역량과 개성을 가진 사람인지 표현해야 하는 만큼 부담감도 클 것이라 생각합니다. 하지만 걱정하지 마세요. 쿡앱스에 지원하실 때는 자기소개서를 받지 않고 있어요. 뛰어난 글쓰기 능력보다, 그 이면에 숨어 있는 지원자님의 역량을 확인해보고 싶습니다.
        </div>
        <img
          v-if="$screen.breakpoint === 'mobile'"
          src="@/assets/careers/process/tellUs_mb.png"
          alt="Tell Us About Yourself"
          class="pt-16 pb-16"
        >
        <img
          v-else-if="$screen.breakpoint === 'tablet'"
          src="@/assets/careers/process/tellUs_tb.png"
          alt="Tell Us About Yourself"
          class="pt-16 pb-16"
        >
        <img
          v-else
          src="@/assets/careers/process/tellUs.png"
          alt="Tell Us About Yourself"
          class="pt-16 pb-16"
        >
        <img
          v-if="$screen.breakpoint === 'mobile'"
          src="@/assets/careers/process/resume_mb.png"
          alt="이력서 작성 순서"
          class="pt-2 pb-16"
        >
        <img
          v-else-if="$screen.breakpoint === 'tablet'"
          src="@/assets/careers/process/resume_tb.png"
          alt="이력서 작성 순서"
          class="pt-2 pb-16"
        >
        <img
          v-else
          src="@/assets/careers/process/resume_dt.png"
          alt="이력서 작성 순서"
          class="pt-2 pb-16"
        >
        <img
          v-if="$screen.breakpoint === 'mobile'"
          src="@/assets/careers/process/focusOn_mb.png"
          alt="Focus On Data"
          class="pt-4 pb-16"
        >
        <img
          v-else-if="$screen.breakpoint === 'tablet'"
          src="@/assets/careers/process/focusOn_tb.png"
          alt="Focus On Data"
          class="pt-4 pb-16"
        >
        <img
          v-else
          src="@/assets/careers/process/focusOn.png"
          alt="Focus On Data"
          class="pt-4 pb-16"
        >
        <img
          v-if="$screen.breakpoint === 'mobile'"
          src="@/assets/careers/process/dataDesc_mb.png"
          alt="우리 데이터 먼저 보고 이야기 나눠볼까요?"
        >
        <img
          v-else-if="$screen.breakpoint === 'tablet'"
          src="@/assets/careers/process/dataDesc_tb.png"
          alt="우리 데이터 먼저 보고 이야기 나눠볼까요?"
        >
        <img
          v-else
          src="@/assets/careers/process/dataDesc_dt.png"
          alt="우리 데이터 먼저 보고 이야기 나눠볼까요?"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
img{
  width: 100%;
}
</style>