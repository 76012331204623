<template>
  <div
    id="step4"
    class="section__common__container"
    style="word-break: keep-all;"
  >
    <div class="pc:flex pc:pt-18 tablet:pt-12 mobile:pt-8 pc:pb-24 tablet:pb-12 mobile:pb-12">
      <div class="pc:w-1/4">
        <div class="pc:text-xl tablet:text-[18px] mobile:text-sm font-bold pb-1.5 text-orange-500">
          STEP 4
        </div>
        <div class="pc:text-5xl tablet:text-[36px] mobile:text-3xl font-bold text-gray-700">
          면접전형
        </div>
      </div>
      <div
        class="pc:w-3/4 mw-845px pt-9"
      >
        <div
          class="text-gray-700 pc:text-2xl tablet:text-xl mobile:text-base pb-14"
          style="word-break: keep-all;"
        >
          과제전형에서 합격하신다면 채용담당자가 유선을 통해 면접 일정을 안내 드립니다. 면접전형은
          쿡앱스와 지원자님의 역량을 상호 평가하는 자리인만큼 면접에서는 긴장을 풀고, 지금까지 쌓아 올린 멋진 경험을 자신있게 말씀해주세요. 쿡앱스에 대해 궁금했던 점이 있다면 질문하는 것도 잊지
          말아주세요. 면접 전형이 쿡앱스 합류를 결정 하시는데 뜻깊은 자리가 되길 바랍니다. 
        </div>
        <div class="pb-6 text-3xl font-bold text-orange-500">
          * 면접전형 절차
        </div>
        <img
          v-if="$screen.breakpoint === 'pc'"
          src="@/assets/careers/process/interview_tit_dt.png"
          alt="1차 실무면접 -> 경영진 면접 -> 결과 안내"
        >
        <img
          v-if="$screen.breakpoint === 'tablet'"
          src="@/assets/careers/process/interview_tit_tb.png"
          alt="1차 실무면접 -> 경영진 면접 -> 결과 안내"
        >
        <img
          v-if="$screen.breakpoint === 'mobile'"
          src="@/assets/careers/process/interview_tit_mb.png"
          alt="1차 실무면접 -> 경영진 면접 -> 결과 안내"
        >
        <div class="pb-6 text-base text-gray-600 pt-9">
          * 직무에 따라 일부 전형이 생략될 수 있습니다.<br>
          * 인턴 전형은 경영진 면접을 생략합니다.<br>
          * 1차 면접자에 한해 온라인 성격 유형 검사를 진행합니다.
        </div>
        <img
          v-if="$screen.breakpoint === 'pc'"
          class="w-full"
          src="@/assets/careers/process/interview_desc_dt.png"
          alt="인터뷰 가이드"
        >
        <img
          v-if="$screen.breakpoint === 'tablet'"
          class="w-full"
          src="@/assets/careers/process/interview_desc_tb.png"
          alt="인터뷰 가이드"
        >
        <img
          v-if="$screen.breakpoint === 'mobile'"
          class="w-full"
          src="@/assets/careers/process/interview_desc_mb.png"
          alt="인터뷰 가이드"
        >
        <div class="pb-5 text-3xl font-bold text-orange-500 pt-14">
          * 면접 참여 전 체크리스트
        </div>
        <img
          v-if="$screen.breakpoint === 'pc'"
          class="w-full"
          src="@/assets/careers/process/interview_check_dt.png"
          alt="인터뷰 체크 리스트"
        >
        <img
          v-if="$screen.breakpoint === 'tablet'"
          class="w-full"
          src="@/assets/careers/process/interview_check_tb.png"
          alt="인터뷰 체크 리스트"
        >
        <img
          v-if="$screen.breakpoint === 'mobile'"
          class="w-full"
          src="@/assets/careers/process/interview_check_mb.png"
          alt="인터뷰 체크 리스트"
        >
        <div class="pb-3 text-3xl font-bold text-orange-500 pt-14">
          * 면접 Tip!
        </div>
        <div
          class="pb-10 text-gray-700 pc:text-xl tablet:text-sm mobile:text-sm"
          style="word-break: keep-all;"
        >
          쿡앱스 면접은 S(Situation: 상황) T(Task: 문제) A(Action: 행동) R(Result: 결과)에 기반하여 진행됩니다.
          아래 STAR Question 내용을 참고하여 면접 전형을 준비해 주세요.
        </div>
        <img
          v-if="$screen.breakpoint === 'pc'"
          class="w-full"
          src="@/assets/careers/process/interview_tip_dt.png"
          alt="인터뷰 팁"
        >
        <img
          v-if="$screen.breakpoint === 'tablet'"
          class="w-full"
          src="@/assets/careers/process/interview_tip_tb.png"
          alt="인터뷰 팁"
        >
        <img
          v-if="$screen.breakpoint === 'mobile'"
          class="w-full"
          src="@/assets/careers/process/interview_tip_mb.png"
          alt="인터뷰 팁"
        >
        <div class="pt-6 pb-2 text-gray-700 pc:text-xl tablet:text-sm mobile:text-sm">
          축구, 야구와 같은 스포츠를 관람하거나 즐기기 위해서는 게임의 규칙과 용어를 알아야 하듯이 쿡앱스에서 주로 사용하고 있는 게임 지표 용어를 숙지하신다면 면접에서 더욱 원활한 소통이 가능합니다. 자세한 내용은 <a
            href="https://www.cookapps.com/cookapps-story/133"
            target="_blank"
            class="text-orange-500 underline"
          >쿡앱스 용어집</a>에서 확인해 주세요! 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
</style>