<template>
  <div class="section__common__container">
    <div
      v-if="$screen.breakpoint === 'pc'"
      class="flex pb-24 pt-18"
    >
      <div
        class="w-1/4 pt-18"
      >
        <div class="pb-4 text-xl text-gray-500">
          PROCESS
        </div>
        <a
          class="block mb-5 text-lg font-bold text-gray-400 indicator hover:text-orange-500"
          @click="moveToSection('step1')"
        >
          쿡앱스에 대해 알아보기</a>
        <a
          class="block mb-5 text-lg font-bold text-gray-400 indicator hover:text-orange-500"
          @click="moveToSection('step2')"
        >
          지원서 작성하기
        </a>
        <a
          class="block mb-5 text-lg font-bold text-gray-400 indicator hover:text-orange-500"
          @click="moveToSection('step3')"
        >
          포트폴리오 구성하기
        </a>
        <a
          class="block mb-5 text-lg font-bold text-gray-400 indicator hover:text-orange-500"
          @click="moveToSection('step4')"
        >
          면접 전형
        </a>
        <a
          class="block mb-5 text-lg font-bold text-gray-400 indicator hover:text-orange-500"
          @click="moveToSection('step5')"
        >
          최종 오퍼
        </a>
      </div>
      <div class="w-3/4">
        <div
          class="pb-4 text-5xl font-bold"
        >
          <b class="fontColorPrimary">쿡앱스</b>의 채용 과정
        </div>
        <div
          class="text-2xl mw-845px"
          style="word-break: keep-all;"
        >
          쿡앱스 채용팀은 지원자님에 대해 궁금한 점을 모두 여쭤보고 있습니다. 그만큼 지원자님께서 궁금하실만한 정보를 전달 드려야 한다고 생각합니다. 이 페이지에서는 지원자님께서 쿡앱스를 알게 된 순간부터, 최종 오퍼를 받게 되는 순간까지, 채용 전반의 과정을 상세하게 설명 드리겠습니다.
        </div>
        <img
          class="pt-12 pb-12"
          src="@/assets/careers/process/process_dt.png"
          alt=""
        >
        <div
          class="text-2xl mw-845px"
          style="word-break: keep-all;"
        >
          쿡앱스 채용팀은 이와 같은 질문들을 매일 마주하고 있습니다. 새로운 도전에는 늘 걱정과 불안감이 뒤따르기 마련입니다. 회사에 대한 정보를 알아보는 첫 단계부터 최종 오퍼를 받게 되는 마지막 과정까지, 지원자님의 새로운 도전을 돕기 위해 쿡앱스 채용팀이 지원자님의 든든한 서포터가 되어 드리겠습니다.
        </div>
      </div>
    </div>
    <div
      v-else-if="$screen.breakpoint === 'tablet'"
    >
      <div
        class="flex pt-12"
      >
        <div class="w-1/2">
          <div
            class="text-[36px] font-bold pt-2"
          >
            <b class="fontColorPrimary">쿡앱스</b>의 채용 과정
          </div>
        </div>
        <div class="w-1/2 text-[18px]">
          <div class="pb-3 text-gray-500">
            PROCESS
          </div>
          <a
            class="block mb-5 font-bold text-gray-400 indicator hover:text-orange-500"
            @click="moveToSection('step1')"
          >
            쿡앱스에 대해 알아보기</a>
          <a
            class="block mb-5 font-bold text-gray-400 indicator hover:text-orange-500"
            @click="moveToSection('step2')"
          >
            지원서 작성하기
          </a>
          <a
            class="block mb-5 font-bold text-gray-400 indicator hover:text-orange-500"
            @click="moveToSection('step3')"
          >
            포트폴리오 구성하기
          </a>
          <a
            class="block mb-5 font-bold text-gray-400 indicator hover:text-orange-500"
            @click="moveToSection('step4')"
          >
            면접 전형
          </a>
          <a
            class="block font-bold text-gray-400 indicator hover:text-orange-500"
            @click="moveToSection('step5')"
          >
            최종 오퍼
          </a>
        </div>
      </div>
      <div class="w-full pb-16 pt-14">
        <div
          class="text-xl mw-704px"
          style="word-break: keep-all;"
        >
          쿡앱스 채용팀은 지원자님에 대해 궁금한 점을 모두 여쭤보고 있습니다. 그만큼 지원자님께서 궁금하실만한 정보를 전달 드려야 한다고 생각합니다. 이 페이지에서는 지원자님께서 쿡앱스를 알게 된 순간부터, 최종 오퍼를 받게 되는 순간까지, 채용 전반의 과정을 상세하게 설명 드리겠습니다.
        </div>
        <img
          class="pt-10 pb-10"
          src="@/assets/careers/process/process_tb.png"
          alt=""
        >
        <div
          class="text-xl mw-704px"
          style="word-break: keep-all;"
        >
          쿡앱스 채용팀은 이와 같은 질문들을 매일 마주하고 있습니다. 새로운 도전에는 늘 걱정과 불안감이 뒤따르기 마련입니다. 회사에 대한 정보를 알아보는 첫 단계부터 최종 오퍼를 받게 되는 마지막 과정까지, 지원자님의 새로운 도전을 돕기 위해 쿡앱스 채용팀이 지원자님의 든든한 서포터가 되어 드리겠습니다.
        </div>
      </div>
    </div>
    <div
      v-else
      class="pt-8"
    >
      <div class="w-full pb-8">
        <div
          class="pb-4 text-3xl font-bold leading-5"
        >
          <b class="fontColorPrimary">쿡앱스</b>의 채용 과정
        </div>
        <div class="pb-[14px] text-gray-500 text-base">
          PROCESS
        </div>
        <a
          class="block mb-5 text-base font-bold text-gray-400 indicator hover:text-orange-500"
          @click="moveToSection('step1')"
        >
          쿡앱스에 대해 알아보기</a>
        <a
          class="block mb-5 text-base font-bold text-gray-400 indicator hover:text-orange-500"
          @click="moveToSection('step2')"
        >
          지원서 작성하기
        </a>
        <a
          class="block mb-5 text-base font-bold text-gray-400 indicator hover:text-orange-500"
          @click="moveToSection('step3')"
        >
          포트폴리오 구성하기
        </a>
        <a
          class="block mb-5 text-base font-bold text-gray-400 indicator hover:text-orange-500"
          @click="moveToSection('step4')"
        >
          면접 전형
        </a>
        <a
          class="block text-base font-bold text-gray-400 indicator hover:text-orange-500"
          @click="moveToSection('step5')"
        >
          최종 오퍼
        </a>
      </div>
      <div class="w-full pb-12">
        <div
          class="text-base text-gray-700 mw-343px"
          style="word-break: keep-all;"
        >
          쿡앱스 채용팀은 지원자님에 대해 궁금한 점을 모두 여쭤보고 있습니다. 그만큼 지원자님께서 궁금하실만한 정보를 전달 드려야 한다고 생각합니다. 이 페이지에서는 지원자님께서 쿡앱스를 알게 된 순간부터, 최종 오퍼를 받게 되는 순간까지, 채용 전반의 과정을 상세하게 설명 드리겠습니다.
        </div>
        <img
          class="pt-8 pb-8"
          src="@/assets/careers/process/process_mb.png"
          alt=""
        >
        <div
          class="pc:text-2xl tablet:text-xl mobile:text-base mw-845px"
          style="word-break: keep-all;"
        >
          쿡앱스 채용팀은 이와 같은 질문들을 매일 마주하고 있습니다. 새로운 도전에는 늘 걱정과 불안감이 뒤따르기 마련입니다. 회사에 대한 정보를 알아보는 첫 단계부터 최종 오퍼를 받게 되는 마지막 과정까지, 지원자님의 새로운 도전을 돕기 위해 쿡앱스 채용팀이 지원자님의 든든한 서포터가 되어 드리겠습니다.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    moveToSection(id) {
      const tag = document.getElementById(id);
      const topPos = this.$screen.breakpoint === 'pc' ? tag.offsetTop - 56 : tag.offsetTop - 90;
      window.scrollTo({
        top: topPos,
        behavior: 'smooth'
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.indicator{
  position: relative;
  cursor: pointer;
  &::before{
    content: '';
    display: inline-block;
    width: 14px; height: 14px;
    border-radius: 100%;
    background-color: #e1e1e1;
    margin-right: 24px;
    position: relative;
    z-index: 1;
  }
  &:hover::before{
    background-color: $orange-500;
  }
  &::after{
    content: '';
    display: block;
    width: 2px; height: 150%;
    position: absolute;
    top: 14px; left: 6px;
    background-color: #e1e1e1;
  }
  &:last-child:after{
    content: unset;
  }
}
</style>