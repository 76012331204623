<template>
  <div
    id="step3"
    class="section__common__container"
    style="word-break: keep-all;"
  >
    <div class="pc:flex pc:pb-24 tablet:pb-12 mobile:pb-12 pc:pt-18 tablet:pt-12 mobile:pt-8">
      <div class="pc:w-1/4">
        <div class="pc:text-xl tablet:text-[18px] mobile:text-sm font-bold pb-1.5 text-orange-500">
          STEP 3
        </div>
        <div
          v-if="$screen.breakpoint === 'pc'"
          class="font-bold text-gray-700 pc:text-5xl tablet:text-3xl mobile:text-3xl"
        >
          포트폴리오<br>
          구성하기
        </div>
        <div
          v-else
          class="pc:text-5xl tablet:text-[36px] mobile:text-3xl font-bold text-gray-700"
        >
          포트폴리오 구성하기
        </div>
      </div>
      <div
        class="pc:w-3/4 mw-845px pt-9"
      >
        <div
          class="text-gray-700 pc:text-2xl tablet:text-xl mobile:text-base"
        >
          게임 제작 역량 검증을 위해 쿡앱스에서는 대부분의 직무에서 포트폴리오를 필수 제출로 안내드리고 있습니다. 과제 전형이 생략되는 직무에서는 포트폴리오를 더욱 꼼꼼히 확인하고 있으니 지원하신
          직무에 알맞은 내용 위주로 구성해 주세요!
          <a
            href="https://www.cookapps.com/cookapps-story/103"
            target="_blank"
            class="text-orange-500 underline"
          >상세한 이력서&포트폴리오 가이드 보러가기</a>
        </div>
        <div class="pt-8">
          <swiper
            ref="tabSwiper"
            :options="swiperOption"
            style="padding-bottom: 0;"
          >
            <swiper-slide>
              <span
                class="tab"
                :class="{ 'on' : activeTab === 'design' }"
                @click="handleTab('design')"
              >
                기획
              </span>
            </swiper-slide>
            <swiper-slide>
              <span
                class="tab"
                :class="{ 'on' : activeTab === 'programming' }"
                @click="handleTab('programming')"
              >
                개발
              </span>
            </swiper-slide>
            <swiper-slide>
              <span
                class="tab"
                :class="{ 'on' : activeTab === 'art' }"
                @click="handleTab('art')"
              >
                아트
              </span>
            </swiper-slide>
          </swiper>
        </div>
        <blockquote class="mt-8 mb-8 text-gray-600 pc:text-2xl tablet:text-xl mobile:text-base">
          {{ tabDesc }}
        </blockquote>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperOption: {
        // loop: true,
        slidesPerView: 'auto',
        preventClicks: true,
        preventClicksPropagation: false,
        observer: true,
        observeParents: true,
        loop: false,
      },
      activeTab: 'design',
      tabDesc: '기획은 “재미”라는 모호한 개념을 게임을 통해 구현하는 첫 단계인만큼, 재미있는 게임에 대해 얼마나 깊이 고민하고 있는지 확인하고 있습니다. 기획안, 제안서 등 공개 가능한 범위 내에서 직접 작성하신 문서를 제출해주세요. 또는 실제 참여하신 프로젝트에서의 가설 검증 과정과 유의미한 결과를 제출해주세요. 게임 제작 경험이 없다면 직접 플레이하는 게임의 장단점을 분석하고 개선안을 기획서 형태로 제작해서 제출하시는 것도 방법 중 하나입니다.',
      tabs: [
        {
          name: 'design',
          desc: '기획은 “재미”라는 모호한 개념을 게임을 통해 구현하는 첫 단계인만큼, 재미있는 게임에 대해 얼마나 깊이 고민하고 있는지 확인하고 있습니다. 기획안, 제안서 등 공개 가능한 범위 내에서 직접 작성하신 문서를 제출해주세요. 또는 실제 참여하신 프로젝트에서의 가설 검증 과정과 유의미한 결과를 제출해주세요. 게임 제작 경험이 없다면 직접 플레이하는 게임의 장단점을 분석하고 개선안을 기획서 형태로 제작해서 제출하시는 것도 방법 중 하나입니다.',
        },
        {
          name: 'programming',
          desc: '게임 개발 역량 수준을 확인하기 위해 직접 commit한 코드를 확인하고 있습니다. 1,000줄 이상 직접 작성하신 코드가 첨부된 깃허브 혹은 샘플 코드를 제출해주세요. 1인 개발, 소규모 팀의 게임 개발 이력도 물론 환영합니다. 직접 개발하신 게임을 제작한 이유, 시장 분석, 결과 분석 등 지원자님의 게임 제작 관련 철학과 성과를 함께 보내주셔도 좋습니다.',
        },
        {
          name: 'art',
          desc: '쿡앱스에서 개발하고 서비스하는 모든 게임은 북미 시장을 타깃으로 제작하고 있는 만큼 해당 마켓에서 시장성이 검증된 화풍으로 아트 리소스를 제작하실 수 있는 분을 선호합니다. 아트 직군의 과제 전형을 통해 화풍, 아트의 퀄리티, 작업 속도를 중점적으로 확인합니다. 아트 직무의 경우 사용자의 선호도 파악이 무엇보다 중요합니다. 과제를 제출하실 때는 UX 관점에서 어떻게 디자인하셨는지 파악할 수 있도록 제작 의도를 함께 알려주시면 검토에 많은 도움이 됩니다.',
        }
      ]
    };
  },
  methods: {
    handleTab(tabName) {
      this.tabDesc = this.tabs.filter(item => item.name === tabName)[0].desc;
      this.activeTab = tabName;
    },
    onTap: function(){}
    // onTap: function(swiper){
    //   const clickIdx = swiper.clickedIndex;
    //   swiper.slideTo(clickIdx -2, 300);
    // }
  },
};
</script>

<style lang="scss" scoped>
.swiper-slide{
  width: auto !important;
}
img{
  width: 100%;
}
.tab{
  display: inline-block;
  width: auto !important;
  padding: 10px 24px;
  border-radius: 22px;
  background-color: #f5f5f5;
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: -0.1px;
  font-weight: bold;
  color: #777;
  margin-right: 8px;
  cursor: pointer;
  &.on, &:hover{
    background-color: #fff2e5;
    color: $primaryColor;
  }
  :is(.mobile) &{
    padding: 8px 20px;
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: -0.1px;
  }
}
blockquote{
  position: relative;
  padding-left: 32px;
  &::before{
    content: '';
    position: absolute;
    top: 0; left: 0;
    width: 2px; height: 100%;
    background-color: #bebebe;
  }
}
</style>